/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .input {
    @apply px-8 py-4 rounded-full border border-gray-300 my-4 bg-white;
  }
  .btn-primary {
    @apply bg-blue-400 text-white rounded-full uppercase;
  }
  .btn-large {
    @apply px-8 py-4 text-lg;
  }

  .h1 {
    @apply text-4xl font-extrabold tracking-tighter;
  }

  .h2 {
    @apply text-3xl font-extrabold tracking-tighter;
  }

  .h3 {
    @apply text-3xl font-extrabold;
  }

  .h4 {
    @apply text-2xl font-extrabold tracking-tight;
  }

  @screen md {
    .h1 {
      @apply text-5xl;
    }

    .h2 {
      @apply text-4xl;
    }
  }

   .btn,
   .btn-lg,
   .btn-sm,
   .btn-xs {
     @apply font-medium text-sm inline-flex items-center justify-center border border-transparent rounded leading-5 shadow-sm transition duration-150 ease-in-out;
   }

  .btn {
    @apply px-3 py-2;
  }

  .btn-lg {
    @apply px-4 py-3;
  }

  .btn-sm {
    @apply px-2 py-1;
  }

  .btn-xs {
    @apply px-2 py-0.5;
  }

   input[type="search"]::-webkit-search-decoration,
   input[type="search"]::-webkit-search-cancel-button,
   input[type="search"]::-webkit-search-results-button,
   input[type="search"]::-webkit-search-results-decoration {
     -webkit-appearance: none;
   }

  .form-input,
  .form-textarea,
  .form-multiselect,
  .form-select,
  .form-checkbox,
  .react-select-container,
  .form-radio {
    @apply text-sm text-gray-800 bg-white border;
  }

  .form-input,
  .form-textarea,
  .form-multiselect,
  .form-select,
  .react-select-container,
  .form-checkbox {
    @apply rounded;
  }

  .react-select-container .react-select__control {
    @apply border-0 shadow-none
  }

  .react-select-container {
    @apply leading-5  border-gray-200 hover:border-gray-300 focus:border-red-300 shadow-sm;
  }

  .react-select-container-error {
    @apply border-red-300 sm:text-sm rounded-md hover:outline-none hover:ring-red-500 hover:border-red-500 sm:text-sm;
  }

  .form-input,
  .form-textarea,
  .form-multiselect,
  .form-select {
    @apply leading-5 py-2 px-3 border-gray-200 hover:border-gray-300 focus:border-red-300 shadow-sm;
  }

  .form-input,
  .form-textarea {
    @apply placeholder-gray-400;
  }

  .react-select-container .react-select__control .react-select__value-container .react-select__placeholder {
    @apply text-gray-400;
  }

  .react-select-container-error .react-select__control .react-select__value-container .react-select__placeholder {
    @apply text-red-300;
  }

  .form-search {
    @apply leading-5 py-2 px-3 border-solitude-400 hover:border-gray-200 placeholder-gray-300 text-sm text-gray-900 bg-white border rounded;
  }

  .dynamic-search-btn {
    @apply border-red-800 text-red-800 hover:bg-red-900 hover:text-white;
  }

  .form-select {
    @apply pr-10;
  }

  .form-checkbox,
  .form-radio {
    @apply text-red-500 border border-gray-300;
  }

  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }


  .scrollable {
    @apply table-auto relative;
  }
  .scrollable thead tr th {
    @apply sticky top-0 whitespace-nowrap ;
    @apply first:bg-red-800 first:text-white first:sticky first:sticky first:left-0 first:z-40;
  }

  .scrollable tbody tr td {
    @apply first:bg-red-800 first:text-white first:sticky first:left-0 first:capitalize;
  }
}

.react-select__menu {
  position: absolute;
  z-index: 100;
}


.react-select-inside-menu .react-select__menu .react-select__menu-list{
  max-height: 8rem;
}


/******************
* datepicker
******************/

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: #5E81F4  !important;
}

.react-datepicker__header {
  min-height: 52px  !important;
  background: #F4F7FC  !important;
  border: none  !important;
  border-radius: 8px 8px 0 0 !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  font-size: 16px !important;
  letter-spacing: 0.05em;
  font-weight: 500  !important;
  padding-bottom: 10px;
  width: 280px;
  color: #00234B !important;
}

.react-datepicker__day-names {
  background: #F4F7FC   !important;
}
.react-datepicker__day{
  color: #374151 !important;
}

.react-datepicker__day--selected{
  background: #00234B  !important;
  color: #fff  !important;
  border-radius: 0 !important;
}

.react-datepicker__day--outside-month{
  color: #999 !important;
}

.react-datepicker__day--disabled{
  color: #ddd !important;

}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__navigation-icon::before{
  border-color: #00234B !important;
}

.react-datepicker-popper[data-placement^="bottom"] {
  position: absolute !important;
}


.react-datepicker-popper{
  z-index: 1000000 !important;
}

.react-datepicker__day-name{
  color: #8181A5 !important;
}

.react-datepicker__navigation{
  top: 10px !important;
}

.react-datepicker{
  border: none !important;
  box-shadow: 0px 6px 20px #999ba81a !important;
  border-radius: 8px !important;
  font-family: 'Lato', sans-serif !important;
  font-size: 14px !important;
  width: 280px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: transparent !important;
  display: none;

}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  display: none;
}

.react-datepicker__time-container {
  border-left: 1px solid #ddd !important;
}

.react-datepicker__input-time-container {
  margin: 0px 0 10px 0px !important;
  text-align: center !important;
}

.react-datepicker__input-container input[type="text"]:disabled{
  color: rgba(156, 163, 175, 1);
  background-color: rgba(243, 244, 246, 1);
  border-color: rgba(229, 231, 235, 1);
  cursor: not-allowed;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: none;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: none;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  -webkit-appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.shim-green {
  position: relative;
  overflow: hidden;
  background-color: rgba(5, 150, 105, 0.7);
}
.shim-green::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
          90deg,
          rgba(233, 233, 233, 1) 0,
          rgba(233, 233, 233, 0.9) 50%,
          rgba(233, 233, 233, 0.8) 100%
  );
  animation: shimmer 2s ease-out infinite;
  content: "";
}

.shim-blue {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 155, 255, 0.7);
}
.shim-blue::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
          90deg,
          rgba(233, 233, 233, 1) 0,
          rgba(233, 233, 233, 0.9) 50%,
          rgba(233, 233, 233, 0.8) 100%
  );
  animation: shimmer 2.5s ease-out infinite;
  content: "";
}

.shim-red {
  position: relative;
  overflow: hidden;
  background-color: rgba(255, 0, 0, 0.7);
}
.shim-red::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
          90deg,
          rgba(233, 233, 233, 1) 0,
          rgba(233, 233, 233, 0.9) 50%,
          rgba(233, 233, 233, 0.8) 100%
  );
  animation: shimmer 3s ease-out infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(0%);
    opacity: 0;
  }
}


.subscription-btn-animated {
  position: relative;
  overflow: hidden;
}

.subscription-btn-animated span:nth-child(1) {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #a166d0, #8e54c9, #7843c2, #6032bb, #4322b5);
  animation: animate1 8s linear infinite;
}

@keyframes animate1 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.subscription-btn-animated span:nth-child(2) {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 3px;
  background: linear-gradient(to bottom, #a166d0, #8e54c9, #7843c2, #6032bb, #4322b5);
  animation: animate2 8s linear infinite;
  animation-delay: 4s;
}
@keyframes animate2 {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

.subscription-btn-animated span:nth-child(3) {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to left, #a166d0, #8e54c9, #7843c2, #6032bb, #4322b5);
  animation: animate3 8s linear infinite;
}

@keyframes animate3 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.subscription-btn-animated span:nth-child(4) {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
  background: linear-gradient(to top, #a166d0, #8e54c9, #7843c2, #6032bb, #4322b5);
  animation: animate4 8s linear infinite;
  animation-delay: 4s;
}

@keyframes animate4 {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}


.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke: #00234B;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 0.8em;
  font-weight: bold;
  fill: #00234B;
}

#pdf-download{
  display: none !important;
}


/* your custom CSS \*/
@keyframes pulsate {
  from {
    transform: scale(0.25);
    opacity: 1.0;
  }
  95% {
    transform: scale(1.3);
    opacity: 0;
  }
  to {
    transform: scale(0.3);
    opacity: 0;
  }
}
@-moz-keyframes pulsate {
  from {
    -moz-transform: scale(0.25);
    opacity: 1.0;
  }
  95% {
    -moz-transform: scale(1.3);
    opacity: 0;
  }
  to {
    -moz-transform: scale(0.3);
    opacity: 0;
  }
}
@-webkit-keyframes pulsate {
  from {
    -webkit-transform: scale(0.25);
    opacity: 1.0;
  }
  95% {
    -webkit-transform: scale(1.3);
    opacity: 0;
  }
  to {
    -webkit-transform: scale(0.3);
    opacity: 0;
  }
}
/* get the container that's just outside the marker image,
    which just happens to have our Marker title in it */
.map-marker div[title="vessel-position"] {
  position: relative;
  animation: animationWave 3s ease-in-out infinite;
  background: rgba(32,150,243,0.4);
  /* make a circle */
  border-radius:150px;
  margin: -68px !important;
  /* set the ring's new dimension and re-center it */
  height:150px!important;
  width:150px!important;
}



/* get the container that's just outside the marker image,
    which just happens to have our Marker title in it */
.map-x div[title="vessel-position"] {
  -moz-animation: pulsate 1.5s ease-in-out infinite;
  -webkit-animation: pulsate 1.5s ease-in-out infinite;
  animation: pulsate 1.5s ease-in-out infinite;
  background: rgba(66, 83, 194, 0.37);
  /* make a circle */
  -moz-border-radius:51px;
  -webkit-border-radius:51px;
  border-radius:51px;
  /* multiply the shadows, inside and outside the circle */
  -moz-box-shadow:inset 0 0 5px #4253c2, inset 0 0 5px #4253c2, inset 0 0 5px #4253c2, 0 0 5px #4253c2, 0 0 5px #4253c2, 0 0 5px #4253c2;
  -webkit-box-shadow:inset 0 0 5px #4253c2, inset 0 0 5px #4253c2, inset 0 0 5px #4253c2, 0 0 5px #4253c2, 0 0 5px #4253c2, 0 0 5px #4253c2;
  box-shadow:inset 0 0 5px #4253c2, inset 0 0 5px #4253c2, inset 0 0 5px #4253c2, 0 0 5px #4253c2, 0 0 5px #4253c2, 0 0 5px #4253c2;
  /* set the ring's new dimension and re-center it */
  height:51px!important;
  margin:-10px 0 0 -10px;
  width:51px!important;
}



.dot{
  margin: auto auto;
  width: 300px;
  height: 300px;
  position: relative;
}


.centraldot{
  width: 6px;
  height: 6px;
  background: rgba(32,150,243,1);
  border-radius: 30px;
  position: absolute;
  left:147px;
  top:147px;
  animation: animationDotCentral linear 3s;
  transform-origin: 50% 50%;
  animation-fill-mode:forwards;
  animation-iteration-count: infinite;
}


.wave{
  width: 260px;
  height: 260px;
  background: rgba(32,150,243,0.4);
  border-radius: 200px;
  position: absolute;
  left:20px;
  top:20px;
  opacity: 0;
  animation: animationWave cubic-bezier(0,.54,.53,1) 3s;
  transform-origin: 50% 50%;
  animation-fill-mode:forwards;
  animation-delay:0.9s;
  animation-iteration-count: infinite;
}

.wave2{
  width: 260px;
  height: 260px;
  background: rgba(32,150,243,0.4);
  border-radius: 200px;
  position: absolute;
  left:20px;
  top:20px;
  opacity: 0;
  animation: animationWave cubic-bezier(0,.54,.53,1) 3s;
  transform-origin: 50% 50%;
  animation-fill-mode:forwards;
  animation-delay:1.07s;
  animation-iteration-count: infinite;
}


@keyframes animationDotCentral{

  0% {
    transform:  scale(0) ;
    opacity: 0;
  }

  5% {
    transform:  scale(2) ;
  }

  10% {
    transform:  scale(0.90) ;
    opacity: 1;
  }


  11% {
    transform:  scale(1.50) ;
  }

  12% {
    transform:  scale(1.00) ;
  }

  28% {
    background: rgba(32,150,243,1);
  }

  29% {
    background: rgba(255,255,255,1);
  }

  31% {
    background: rgba(32,150,243,1);
  }

  33% {
    background: rgba(255,255,255,1);
  }

  35% {
    background: rgba(32,150,243,1);
  }

  90%{
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes animationWave{
  0% {
    opacity: 0;
    transform:  scale(0.00);
  }

  1% {
    opacity: 1;
  }

  10% {
    background: rgba(32,150,243,0.4);
  }

  100% {
    transform:  scale(1) ;
    background: rgba(32,150,243,0.0);
  }
}

.draggable-prefix {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}


.filter .react-datepicker__tab-loop .react-datepicker-popper {
  position: fixed !important;
}

.react-pdf__Page__canvas {
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}


.richText p {
  margin-bottom: 1em;
  line-height: 1.6;
  font-size: 16px;
}

/* Stili per titoli di vario livello */
.richText h1, .richText h2, .richText h3, .richText h4, .richText h5, .richText h6 {
  margin-top: 35px;
  font-weight: bold;
}

.richText h1 {
  font-size: 2em;
}

.richText h2 {
  font-size: 1.8em;
}

.richText h3 {
  font-size: 1.6em;
}

.richText a {
  color: #007bff;
  text-decoration: none;
}

.richText a:hover {
  text-decoration: underline;
}

/* Stili per liste */
.richText ul, .richText ol {
  margin-bottom: 0.5em;
  padding-left: 0.5em;
  list-style-type: circle;

}

/* Stili per elementi in grassetto e corsivo */
.richText strong, .richText b {
  font-weight: bold;
}

.richText em, .richText i {
  font-style: italic;
}



.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.number-popup {
  position: absolute;
  color: #ffffff;
  font-size: 32px;
  font-weight: 900;
  pointer-events: none;
  /* Makes the element non-interactive */
  user-select: none;
  /* Prevents text selection */
}

.level-image:after {
  content: "";
  background: #ffffff;
}


.swiper-pagination {
  position: relative !important;
  bottom: 0 !important;
  display: flex !important;
  justify-content: center !important;
  margin-top: 40px !important;
  width: 100% !important;
  /* Adjust spacing as needed */
}

.swiper-pagination-bullet {
  background-color: white !important;
  /* Pagination bullet color */
  opacity: 0.7;
  margin-left: 5px !important;
}

.swiper-pagination-bullet-active {
  opacity: 1;
}

.swiper-slide:not(.swiper-slide-active) {
  opacity: 0 !important;
}

@keyframes moveUpFadeOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-90px);
    opacity: 0;
  }
}

.number-popup {
  position: absolute;
  animation: moveUpFadeOut 0.7s ease-out forwards;
  /* L'animazione dura 0.7s e si ferma allo stato finale */
  will-change: transform, opacity;
}


/* Slide in from the left */
.slide-enter-left {
  animation: slideInFromLeft 0.5s forwards;
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Slide out to the left */
.slide-exit-left {
  animation: slideOutToLeft 0.5s forwards;
}

@keyframes slideOutToLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

/* Slide in from the right */
.slide-enter-right {
  animation: slideInFromRight 0.5s forwards;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Slide out to the right */
.slide-exit-right {
  animation: slideOutToRight 0.5s forwards;
}

@keyframes slideOutToRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.mole {
  display: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 80%;
  width: 80%;
  border: 0;
  margin-left: auto;
  margin-right: auto;
}

.mole-hole {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-bottom: 2vmin solid rgb(31 41 55);
}

.moles {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 20vmin);
  grid-template-rows: repeat(2, 20vmin);
  align-items: center;
}

.moles > *:nth-of-type(4),
.moles > *:nth-of-type(5) {
  transform: translate(50%, 0);
}

.info {
  position: fixed;
  top: 1rem;
  left: 1rem;
}

.info-text {
  font-size: 2rem;
}


.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
